import Image from 'react-bootstrap/Image'
function Banner() {
    return (
        <>
            <div className="d-flex justify-content-center mt-banner">
                <Image src="https://awjftp.blob.core.windows.net/awjcontents/websites/catch22/logo.webp" />
            </div>
            <div className="d-flex justify-content-center my-5">
                <h1 className="text-uppercase text-light text-shadow ft-bignoodletitling fs-banner">Let's Talk Food</h1>
            </div>
        </>
    );
}

export default Banner;
