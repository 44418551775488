import Image from 'react-bootstrap/Image'
function Footer() {
    return (
        <>
            <div className="d-flex justify-content-center mt-banner pb-5">
                <Image src="https://awjftp.blob.core.windows.net/awjcontents/websites/catch22/logo.webp" />
            </div>
        </>
    );
}

export default Footer;
