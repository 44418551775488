import React, { useState, useEffect, useRef } from 'react'
import './design'
/*React toastify library for notifications*/
import { ToastContainer, toast } from 'react-toastify'
import Placeholder from 'react-bootstrap/Placeholder'
import Survey from './componets/survey'
import BANNER from './componets/banner'
import Footer from './componets/footer'
function App() {
  const [loadingQuestions, setLoadingQuestions] = useState(true)
  const dataRefQuestions = useRef([])
  const notify = (MSG) => toast(MSG); //Function to show system's notification
  const warning = (MSG) => toast.error(MSG); //Function to show system's error notification

  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var slugurl = url.searchParams.get("slug");
    if(slugurl === null ) slugurl = "catch22jbr"
    fetch('https://its-survey-catch22.azurewebsites.net/feedback/getFormBySlug/'+slugurl)
      .then(response => response.json())
      .then(data => {
        dataRefQuestions.current = data
        setLoadingQuestions(false)
      })
      .catch(error => console.log(error))
  }, [])
  return (
    <div className="App">
      <BANNER />
      { !loadingQuestions ? <Survey dataRefQuestions={dataRefQuestions.current} notify={notify} warning={warning}/> : <Placeholder as="p" animation="glow"><Placeholder className="vh-100" xs={12} size="lg" /></Placeholder>}
      <Footer />
      {/*Notification box configuration*/}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
