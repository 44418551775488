import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
function Survey({ dataRefQuestions, notify, warning }) {
    const initialState = {
        FeedbackFormID: "",
        email: "",
        name: "",
        phone: "",
    }
    const [factors, setFactors] = useState([])
    const [formData, setFormData] = useState(initialState)
    const updateFormData = (paramName, paramValue) => {
        setFormData((prevOrderData) => ({
            ...prevOrderData,
            [paramName]: paramValue,
        }));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }
    function handleFactors(event) {
        const { value, checked } = event.target;

        if (checked) {
            setFactors((prevSelectedItems) => [...prevSelectedItems, value]);
        } else {
            setFactors((prevSelectedItems) =>
                prevSelectedItems.filter((item) => item !== value)
            );
        }

    }

    function handleSelectedScale(e) {
        updateFormData(e.target.name, e.target.getAttribute('data-value'))
    }
    useEffect(() => {
        let multi = ''
        switch (dataRefQuestions.body.CFG_FeedbackQuestions[0].FeedbackFormID) {
            case 7:
                multi = 'RQ_39'
                break;
            case 8:
                multi = 'RQ_27'
                break;
            case 9:
                 multi = 'RQ_5'
                break;
            default:
                multi = 'RQ_50'
                break;
        }
        updateFormData(multi, factors.toString())
    }, [factors,dataRefQuestions.body.CFG_FeedbackQuestions])

    useEffect(() => {
        updateFormData("FeedbackFormID", dataRefQuestions.body.CFG_FeedbackQuestions[0].FeedbackFormID)
    }, [dataRefQuestions.body.CFG_FeedbackQuestions])


    async function handleAddFeedback() {
        let url = 'https://its-survey-catch22.azurewebsites.net/feedback-response/save'
        const params = formData
        console.log(formData)
        const hasEmptyValue = Object.values(params).some(value => value === "")
        if (hasEmptyValue) {
            warning("Please fill all form data.")
        }
        else {
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(params),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();
                const result = responseData.error
                if (result) {
                    warning("Error adding survey!")
                }
                else {
                    setFormData(initialState)
                    notify("Survey is submited")
                }
            } catch (error) {
                console.error('Error while sending POST request:', error);
            }
        }
    }

    return (
        <Container className="mt-5">
            <Row className="my-5">
                <Col>
                    <h2 className="text-uppercase text-form">Full Name:</h2>
                    <input type="text" className="form-control form-control-lg form-input" id="name" name="name" onChange={handleChange} />
                </Col>
            </Row>
            <Row className="my-5">
                <Col>
                    <h2 className="text-uppercase text-form">Email Address:</h2>
                    <input type="email" className="form-control form-control-lg form-input" id="email" name="email" onChange={handleChange} />
                </Col>
            </Row>
            <Row className="my-5">
                <Col>
                    <h2 className="text-uppercase text-form">Contact Number:</h2>
                    <input type="text" className="form-control form-control-lg form-input" id="phone" name="phone" onChange={handleChange} />
                </Col>
            </Row>
            {
                dataRefQuestions.body.CFG_FeedbackQuestions?.map((question, index) => {
                    if (question.C_QuestionType.Name === "text") {
                        return (
                            <Row className="my-5">
                                <Col>
                                    <h2 className="text-uppercase text-form">{question.Question}</h2>
                                    <textarea className="form-control form-input" rows={5} placeholder="Type here..." id={"Q_" + question.Id} name={"Q_" + question.Id} onChange={handleChange}></textarea>
                                </Col>
                            </Row>
                        )
                    } else {
                        return (
                            <Row className="text-left mb-5">
                                <Col>
                                    <h2 className="text-uppercase text-form">{question.Question}</h2>
                                    <div className={question.C_QuestionType.Name === "rating" ? "d-flex justify-content-between max-w-scale" : ""}>
                                        {
                                            question.CFG_FeedbackResponses?.map((answer, indexa) => {
                                                if (question.C_QuestionType.Name === "radio") {
                                                    return (
                                                        <Form.Check
                                                            type="radio"
                                                            name={"Q_" + question.Id}
                                                            label={answer.Response}
                                                            className="text-light secondary-font fs-5"
                                                            value={answer.Id}
                                                            onChange={handleChange}
                                                        />
                                                    )
                                                } else if (question.C_QuestionType.Name === "multiselect") {
                                                    return (
                                                        <Form.Check
                                                            type="checkbox"
                                                            name={"RQ_" + question.Id}
                                                            label={answer.Response}
                                                            value={answer.Id}
                                                            className="text-light secondary-font fs-5"
                                                            onChange={handleFactors}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <>
                                                            <input type="radio" className="btn-check" name={"Q_" + question.Id} id={"Q-options" + (indexa + 1)} onChange={handleSelectedScale} data-value={answer.Id} />
                                                            <label className="btn btn-outline-danger rounded-circle fs-4 p-0 border" htmlFor={"Q-options" + (indexa + 1)}><span className="nd-options-label">{(indexa + 1)}</span></label>
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </Col>
                            </Row>
                        )
                    }
                })
            }
            <Row className="text-center">
                <Col onClick={handleAddFeedback}>
                    <Button className="btn-submit" >Submit</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default Survey;
